<template>
  <div style="width: 100%; height: 100%; position: relative;" id="parent">
    <div class="left_tab_parent">
      <div class="left_tab">
        <div
          v-for="(item, index) in tab_list"
          :key="index"
          :class="{ isChoose: isChoose == index }"
          @click="showTab(index)"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <div class="right_content_parent">
      <div class="right_content">
        <div v-for="(item, index) in list" v-if="item.focusList.length > 0">
          <div class="date_box">
            <div class="date_fs">{{item.focusDate}}</div>
          </div>
          <div class="item_box">
            <div v-for="(itemc, indexc) in item.focusList" class="item" @click="toPage(itemc.typeId)">
              <div v-if="isChoose === 0" class="flex-align-center">
                <img src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/web-static/znsearch/images/i2.png" class="item-img">
                <div class="item-content">
                  <div class="item-tit ellipse-2">{{itemc.content.title}}</div>
                  <div>
                    <div class="item-desc item-bk" style="background:#FFDBCD;color:#FE7930">{{itemc.content.achieveType}}</div>
                  </div>
                  <div class="item-desc">所属高校：{{itemc.content.colleges}}</div>
                  <div class="item-desc">应用方向：{{itemc.content.category}}</div>
                </div>
                <div class="collect-btn" @click.stop="collectBtnOther(itemc, 2)"><Icon type="md-heart-outline"/>取消关注</div>

              </div>
              <div v-if="isChoose === 1" class="flex-align-center">
                <img :src="itemc.content.goods.mainImageUrl" class="item-img">
                <div class="item-content">
                  <div class="item-tit ellipse-2">{{itemc.content.goods.name}}</div>
                  <div>
                    <div class="item-desc item-bk">{{itemc.content.classifyOne}}</div>
                    <div class="item-desc item-bk" style="background:#FFDBCD;color:#FE7930">{{itemc.content.classifyTwo}}</div>
                  </div>
                  <div class="item-price">服务价格：{{ itemc.content.goodsStock.price }}</div>
                </div>
                <div class="collect-btn" @click.stop="collectBtn(itemc, 3)"><Icon type="md-heart-outline"/>取消关注</div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="enterprise-footer" style="text-align: center;background:#f4f8ff">
      <a style="color: #515a6e" href="https://beian.miit.gov.cn/" target="_blank">连云港科技大市场版权所有2013-2024 苏ICP备18024573号-2. All Rights Reserved.</a>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { collectList, collect } from "@/plugins/api/collect";
export default {
  name: "EnterpriseSchoolConnection",
  mounted() {

    this.changeHeight();
    this.getList()
  },

  data() {
    return {
      list: [],
      tab_height: 0,
      tab_list: ["科技成果", "科技服务"],
      isChoose: 0,
      typeUrl: ["/home/PatentSearchDetail?id=", "/home/serviceDetail?goodId="],
    };
  },
  methods: {
    toPage(id) {
      this.$router.push({path: this.typeUrl[this.isChoose] + id});
    },
    getList() {
      let type = this.isChoose + 2;
      collectList({type: type}).then(data => {
        this.list = data.result
        this.list.forEach(v => v.focusList.forEach(k => k.content = JSON.parse(k.content)))
      });
    },
    showTab(index) {
      this.isChoose = index;
      this.getList();
    },
    collectBtn(itemc, type) {
      collect({
        typeId: itemc.content.goods.id,
        type,
        content: JSON.stringify(itemc)
      }).then((data) => {
        if (data.code === 0) {
          this.getList()
        }
      });
    },
    collectBtnOther(itemc, type) {
      collect({
        typeId: itemc.content.id,
        type,
        content: JSON.stringify(itemc)
      }).then((data) => {
        if (data.code === 0) {
          this.getList()
        }
      });
    },
    changeHeight() {
      let that = this;
      this.$nextTick(() => {
        that.tab_height =
            Number($(".right_content_parent").height()) >
            Number(document.body.clientHeight) - 101
                ? $(".right_content_parent").height() + "px"
                : Number(document.body.clientHeight) - 101 + "px";
      });
    },
  },
};
</script>

<style scoped lang="scss">
.ivu-select-selection {
  border-radius: 0 !important;
}
.arrow_ld {
  display: inline-block;
  position: absolute;
  right: 0;
  & > span {
    display: inline-block;
    height: 20px;
    width: 20px;
    background-color: #1767E0;
    text-align: center;
    line-height: 20px;
    color: white;
  }
}
.qiye,
.qiye td {
  border: 1px solid #1767E0;
}
.qiye {
  border-bottom: none;
  border-right: none;
}
.qiye td {
  border-top: none;
  border-left: none;
}

.qiye td:nth-child(1) {
  width: 100px;
  text-align: right;
}

.qiye td:nth-child(2) {
  width: 310px;
}

.qiye td:nth-child(3) {
  width: 130px;
  text-align: right;
}

.qiye td:nth-child(4) {
  width: 260px;
}

.span_cg {
  font-size: 18px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.span_yyjs {
  display: inline-block;
  text-align: center;
  font-size: 14px;
  height: 20px;
  line-height: 20px;
  width: 60px;
  border-width: 1px;
  background-color: rgb(254, 164, 100);
  opacity: 0.8;
  overflow: hidden;
  position: absolute;
  right: 20px;
}

.ivu-col {
  border: none !important;
}

.neirong {
  margin-left: 5px;
  .nei_rong_box {
    height: 620px;
    width: 100%;
    border-bottom: 2px dotted #f5f5f5;
  }
  p {
    display: inline-block;
    margin-bottom: 20px;
    font-size: 14px;
    color: black;
  }
}

.bottom_style {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: -110px;
}

.common_liucheng:nth-child(1) {
  border: none;
}

.common_liucheng {
  width: 95%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  & > span {
    display: block;
    padding-left: 5px;
    padding-top: 38px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    color: rgb(51, 51, 51);
    margin-bottom: 30px;
  }
}

.next_style {
  display: flex;
  align-items: center;
  justify-content: center;
}

.right_real_content {
  margin-bottom: 50px;
  width: 95%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 40px;
}

.right_header {
  width: 95%;
  padding-top: 20px;
  height: 140px;
  position: relative;
  margin-bottom: 40px !important;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 2px dotted #f5f5f5;
  overflow: hidden;
  span {
    display: block;
    margin-bottom: 20px;
  }
}

.button_style {
  display: inline-block;
  background-color: #1767E0;
  height: 32px;
  z-index: 51;
  line-height: 32px;
  text-align: center;
  font-size: 14px;
  color: white;
  padding: 0 27px;
  border-radius: 4px;
}

.input_search,
.input_search:focus {
  border-width: 1px;
  border-color: rgb(55, 197, 207);
  border-style: solid;
  border-radius: 2px;
  background-color: rgb(255, 255, 255);
  box-shadow: 6px 0px 15px 0px rgba(127, 127, 127, 0.1);
  width: 300px;
  height: 40px;
  outline: none;
}

.isChoose {
  color: #37c5cf;
}

.right_content {
  /*width: 1260px;*/
  margin: 0 auto;
  /*border-width: 1px;*/
  /*border-color: rgb(218, 226, 229);*/
  /*border-style: solid;*/
  min-width: 1210px;
  height: auto;
}

.right_content_parent {
   // width: 90%;
  min-width: 1210px;
  position: relative;
  width: calc(100% - 160px);
  margin-left: 160px;
  background-color: #f6faff;
  padding-bottom: 20px;
  min-height: calc(100% - 46px);
}

.left_tab_parent {
  height: 100%;
  min-width: 160px;
  width: 160px;
  background-color: #D5E7FA;
  position: fixed;
  top: 101px;
  .left_tab {
    div {
      height: 61px;
      line-height: 61px;
      font-size: 14px;
      color: #597697;
      padding-left: 20px;
      cursor: pointer;
      border-bottom: 1px solid #BDDBFA;
      &.isChoose {
        color: #fff;
        background-color: #1767E0;
      }
    }
  }
}
.date_box{
  height: 60px;
  background: #fafafc;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  margin: 0 auto;
}
.date_fs{
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.item_box{
  // margin-top: 32px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width:100%;
  padding:0 10px;
}
.item{
  background: #FFFFFF;
  border-radius: 8px;
  cursor: pointer;
  margin: 0 10px;
  margin-bottom: 20px;
  box-shadow: 6px 6px 15px 0px rgba(127, 127, 127, 0.3);
  width: calc(50% - 20px);
  padding: 30px 0 30px 20px;
  position: relative;
  /*margin-left: 40px;*/
  &:hover{
    .item-tit {
      color: #0F61DF;
    }
  }
}
.item-img{
  width: 214px;
  height: 120px;
  /*padding: 0 20px;*/
/*  background: url("../../assets/image/science/his-bg.png");
  background-size:cover;
  display: flex;
  align-items: center;
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 30px;*/

}
.item-content{
  padding-left:20px;
}
.item-tit{
  height: 40px;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 20px;
}
.item-desc{
  margin-top: 8px;
  font-size: 14px;
  font-weight: 400;
  &.item-bk{
    color: #0F62DF;
    background: #CADFFE;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 4px;
    margin-right: 13px;
  }
}
.item-price{
  margin-top: 15px;
  font-size: 16px;
  color: #333333;
}

.ellipse-2 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: normal !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.delete-box{
  display: none;
  align-items: center;
  cursor: pointer;
}
.delete-img{
  width: 16px;
  height: 16px;
}
.delete-fs{
  height: 14px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #36C2CF;
  line-height: 14px;
  margin-left: 10px;
}
  .date_box:hover .delete-box{
      display:flex;
  }
  .collect-btn{
    position: absolute;
    color: #0F62DF;
    background: #CADFFE;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid #0F62DF;
    top: 50%;
    transform: translate(0, -50%);
    right: 20px;
  }

</style>
